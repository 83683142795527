<template>
    <div>
        <page-title>{{ $t('menu.canteen_calendar') }}</page-title>
        <v-row>
            <v-col>
                <form-panel v-if="date" id="canteen-calendar">
                    <div class="navs">
                        <template v-if="currentLocale === 'en'">
                            {{ date.format('MMMM YYYY') }}
                        </template>
                        <template v-else>
                            {{ date.format('YYYY MMMM') }}
                        </template>
                        <v-btn icon @click="prevMonth">
                            <v-icon>navigate_before</v-icon>
                        </v-btn>
                        <v-btn icon @click="nextMonth">
                            <v-icon>navigate_next</v-icon>
                        </v-btn>
                    </div>
                    <div class="days">
                        <div
                            v-for="(day, index) in days"
                            :key="`${currentLocale}-${index}`"
                            class="day"
                        >
                            <div class="day-header">
                                <div
                                    class="date"
                                    :class="{ today: today.isSame(day, 'day') }"
                                >
                                    {{ day.format('D') }}
                                </div>
                                {{ day.format('ddd') }}
                            </div>
                            <div>
                                <div
                                    v-for="(order, index) in getOrders(day)"
                                    :key="index"
                                    class="order"
                                >
                                    <img
                                        width="100%"
                                        :src="
                                            order.relatedRestaurants[0]
                                                .cardImageMedia.public_url
                                        "
                                    />
                                    <div class="desc">
                                        <div
                                            v-for="(restaurant,
                                            index) in order.relatedRestaurants"
                                            :key="index"
                                            class="restaurant"
                                        >
                                            <div
                                                class="font-weight-bold text--primary"
                                            >
                                                {{ restaurant.restaurantName }}
                                            </div>
                                            <div class="text--secondary">
                                                {{ $t('orders.cutoff') }}:
                                                {{
                                                    restaurant.cutoffTimeOverride
                                                        ? restaurant.cutoffTimeOverride
                                                        : order.cutoffTime
                                                          | transformDate
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    order.budgetLimit | currency
                                                }}
                                                {{ $t('labels.per_person') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form-panel>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CloudcanteensApiClient from '@/api/RestApi/CompanyCloudcanteens'
import PageTitle from '@/components/layout/components/pageTitle.vue'
import FormPanel from '@/components/form/FormPanel.vue'
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'

export default {
    components: { FormPanel, PageTitle },
    data() {
        return {
            today: null,
            date: null,
            items: [],
        }
    },
    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        days() {
            if (!this.date) {
                return []
            }
            const items = []
            let start = this.date.startOf('month')
            const end = this.date.endOf('month')

            while (start < end) {
                items.push(start)
                start = start.add(1, 'day')
            }
            return items
        },
    },
    watch: {
        currentLocale() {
            this.$forceUpdate()
        },
    },
    mounted() {
        this.date = this.$date()
        this.today = this.$date()
        this.loadItems()
    },
    methods: {
        async loadItems() {
            const start = this.date.startOf('month')
            const end = this.date.endOf('month')

            this.items = (
                await CloudcanteensApiClient.list({
                    'deliveryTime[after]': start.toDate(),
                    'deliveryTime[before]': end.toDate(),
                })
            )['hydra:member']
        },
        getOrders(date) {
            return this.items.filter((x) => {
                return this.$date(x.deliveryTime).isSame(date, 'day')
            })
        },
        nextMonth() {
            this.date = this.date.add(1, 'month')
            this.loadItems()
        },
        prevMonth() {
            this.date = this.date.subtract(1, 'month')
            this.loadItems()
        },
    },
}
</script>
<style scoped lang="scss">
#canteen-calendar {
    .navs {
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 10px;
    }
    .days {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 1px;

        .day {
            padding: 10px;
            min-height: 100px;
            outline: 1px solid #eee;

            .day-header {
                display: flex;
                align-items: center;
                line-height: 1;
                margin-bottom: 10px;

                .date {
                    border-radius: 50%;
                    font-size: 18px;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;

                    &.today {
                        background: #333;
                        color: white;
                        margin-right: 6px;
                    }
                }
            }

            .order {
                font-size: 12px;

                img {
                    border-radius: 4px;
                    display: block;
                    margin-bottom: 4px;
                }

                .desc {
                    padding: 4px;
                    line-height: 1.2;

                    .text--primary {
                        font-size: 13px;
                    }

                    div {
                        margin-bottom: 4px;
                    }

                    .restaurant {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
</style>
